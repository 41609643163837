.navBlack {
	/* background: #fff; */
	background: #000;
	position: fixed;
	width: 100%;
	z-index: 1200;
	padding: 0.5rem 0;
}
.navWhite {
	background: #fff;
	position: fixed;
	width: 100%;
	z-index: 1200;
	padding: 0.5rem 0;
}

.nav-logo-and-toggleBtn {
	display: flex;
	align-items: center;
	justify-content: space-between;
}
/* .nav-toggle {
	transition: all 0.3s linear;
	cursor: pointer;
	padding: 0.25rem 0.75rem;
	font-size: 1.25rem;
	line-height: 1;
	border: 1px solid transparent;
	border-radius: 0.25rem;
}
.nav-toggle:hover {
	transform: rotate(90deg);
} */

.nav-link {
	padding: 0.5rem 1rem;
	font-weight: 400;
	color: #2e3349;
	text-transform: uppercase;
	font-size: 0.85rem;
	font-weight: bold;
	letter-spacing: 0.1em;
	transition: all 0.3s;
}

.navLinkColorWhite {
	color: #fff !important;
}

.nav-link.disabled {
	color: #6c757d;
}

.nav-link.active,
.nav-link:hover,
.nav-link:focus {
	color: #ff3f3f;
	text-decoration: none;
}

.nav-center {
	width: 80%;
	margin: 0 auto;
}

.links-container {
	height: 0;
	overflow: hidden;
	transition: all 0.3s ease-in-out;
	/* transform: scaleY(0); 
	transform-origin: top; */
}

.show-container {
	height: 300px;
	/* transform: scaleY(1); */
	transition: all 0.3s ease-in-out;
}

.links {
	margin: 1rem auto;
	padding: 0;
}

.links a {
	padding: 0;
	margin: 0 0.5rem;
}

.links li {
	padding: 0;
	margin: 0 0 0.5rem 0;
	list-style-type: none;
}

a.nav-link {
	margin: 0;
}

@media screen and (min-width: 1126px) {
	nav {
		padding: 0;
	}
	.nav-center {
		width: 80%;
		margin: 0 auto;
		display: flex;
		justify-content: space-between;
		padding: 1rem;
	}

	.nav-header {
		padding: 0;
	}
	.nav-toggle {
		display: none;
	}
	.links-container {
		height: auto;
	}
	.links {
		display: flex;
	}
	.links a {
		padding: 0;
		margin: 0 0.5rem;
	}
}