.hiro {
  position: relative;
}

.joeImg {
  position: relative;
  top: 0;
  left: 0;
  width: 280px;
  z-index: 1;
}

.container {
  width: 40%;
  margin-left: 48% !important;
  margin-top: 17% !important;
  position: absolute;
  z-index: 1;
}
.red {
  color: #ff3f3f;
}
.white {
  color: white;
  font-weight: 900;
}
.whites {
  color: white;
  font-weight: 500 !important;
}
.color {
  color: #ff3f3f !important;
  font-weight: 400 !important;
  margin-bottom: -1% !important;
}
.font {
  text-transform: uppercase !important;
  color: white;
  font-family: inherit;
  font-weight: 800;
  line-height: 1.2;
  font-size: 300%;
  letter-spacing: 0.1em;
}
/* TABLET SCREEN  */

/* SMALL SCREEN  */

@media (max-width: 877px) {
  .whites {
    color: white;
    font-weight: 500 !important;
    /* font-size: small !important; */
  }
}

/* SMALL SCREEN */
@media (max-width: 679.98px) {
  .whites {
    color: white;
    font-weight: 300 !important;
    font-size: small !important;
  }
}
