.flex {
	display: flex;
	/* flex-direction: row !important; */
	justify-content: space-evenly;
}
@media (max-width: 591.98px) {
	.flex {
		display: block;
		margin: 010%;
	}
	.marginBottom {
		margin-bottom: 30% !important;
	}
}
.icon {
	color: #ff3f3f;
	transition: all 0.3s;
	font-weight: 900;
	/* margin-top: 2.3rem; */
	/* margin-bottom: 0 !important; */
	/* font-size: 4em; */
}
.box:hover {
	background-color: "red" !important;
	opacity: 30;
	transition: all 0.4s;
}
@media (max-width: 591.98px) {
	.box:hover {
		background-color: white;
	}
}
@media (min-width: 591.98px) {
}
/* .h4:hover {
  color: aliceblue;
} */
.margin-bottom {
	margin-bottom: 0 !important;
}
.margin {
	margin-bottom: 0 !important;
}
.img {
	/* width: 3rem; */
	/* width: 20; */
	height: 40px;

	/* margin-top: 2.3rem; */
	transition: all 0.3s;
	/* font-weight: 900; */
	margin-bottom: 0 !important;
}
.text-primary {
	color: #ff3f3f !important;
}
.font-weight-bold {
	font-weight: 800 !important;
	margin-right: 10% !important;
}

.letter-spacing {
	letter-spacing: 0.3em !important;
}
.padding {
	width: 30em !important;
}
.btn {
	font-weight: 400;
	display: flex;

	border: 1px solid transparent;
	padding: 0.375rem 0.75rem;
	font-size: 1rem;
	line-height: 1.5;
	border-radius: 0.25rem;
	transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
		border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.btn-primary {
	color: #fff;
	background-color: #ff3f3f;
	border-color: #ff3f3f;
}
.btn-primary:hover {
	color: #ff3f3f;
	background-color: #fff;
}
.text-align {
	text-align: center !important;
}
/* .name {
  padding: 0.5em !important;
  padding-left: 20em !important;
} */
.width {
	width: 59%;
	margin-left: 15% !important;
}

.contact-item {
	background: #fff;
	position: relative;
	transition: all 0.4s;
}

.contact-item::before {
	content: "";
	display: block;
	position: absolute;
	top: 4px;
	left: 4px;
	width: calc(100% - 8px);
	height: calc(100% - 8px);
	background: none;
	border: 2px solid #fff;
	-webkit-transform: scale(1.1);
	transform: scale(1.1);
	opacity: 0;
	transition: all 0.3s;
}

.contact-item i {
	color: #ff3f3f;
	transition: all 0.3s;
}

.contact-item:hover {
	background: #ff3f3f;
	color: #fff;
}

.contact-item:hover i,
.contact-item:hover .contact-item-title,
.contact-item:hover p {
	color: #fff;
}

.contact-item:hover::before {
	-webkit-transform: none;
	transform: none;
	opacity: 1;
}

a.contact-item .contact-item-title,
a.contact-item p {
	transition: all 0.3s;
}
