.text {
  color: #ff3f3f;
  margin: 0px 8px 0px 0px;
}
.paragraphe {
  padding-left: 0.4rem !important;
  padding-right: 0.7rem !important;
  margin-left: 1.5rem !important;
  margin-top: 0;
  margin-bottom: 1rem;

  /* margin-bottom: 0.5rem; */
  font-family: inherit;
  /* font-weight: 800; */
  /* line-height: 1.5; */
  color: inherit;
  /* align-items: ; */
  justify-self: center;
}
.bold {
  font-weight: 900;
}
