.list-inline {
    list-style-type: none;
    position: relative;
    justify-content: space-between;
    padding: 1rem;
}

.list-inline-item {
    display: inline;
    list-style-type: none;
    padding-left: 1.5rem;
    /* width: 20%; */
}

.copyrights {
    width: 60%;
    text-align: center;
    padding: 1rem;
    margin-bottom: 0px;
}